import { Container } from "react-bootstrap";
import Markdown from "react-markdown";
import { useLoaderData } from "react-router-dom";


export default function Milestones() {
  const milestones = useLoaderData();

  return <Container fluid="xxl">
    <h1 className="mb-4">Požiadavky priebežných odovzdaní</h1>
    <div className="row g-4">
      {milestones.dp && (
        <MilestonesList title="Diplomové práce" milestones={milestones.dp} />
      )}
      {milestones.bp && (
        <MilestonesList title="Bakalárske práce" milestones={milestones.bp} />
      )}
    </div>
  </Container>;
}

function MilestonesList({ title, milestones }) {
  return <div className="col-sm-6">
    <h2>{title}</h2>
    <dl>
      {milestones.map((milestone) => <Milestone milestone={milestone} key={milestone.id} />)}
    </dl>
  </div>;
}

function Milestone({ milestone }) {
  return <>
    <dt>{milestone.title} <span className="text-secondary">({formatDate(milestone)})</span></dt>
    <dd><Markdown>{milestone.description}</Markdown></dd>
  </>;
}

function formatDate(milestone) {
  const dateOptions = {
    year: "numeric",
    month: "long",
    day: "numeric",
  };
  return new Date(milestone.deadline).toLocaleDateString("sk", dateOptions);
}
