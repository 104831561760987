import { useRouteLoaderData, useRouteError } from "react-router-dom";
import { Container } from "react-bootstrap";
import Base from "./Base";

function redirectToAuth() {
  window.location.assign(
    "/auth/login/gitlab/?next=" + window.location.pathname,
  );
}

export default function ErrorPage() {
  const error = useRouteError();
  const data = useRouteLoaderData("root");
  console.log("ErrorPage", error, data);
  const user = data?.user;
  if (error.status === 403 && user === null) {
    redirectToAuth();
    return;
  }
  const department = data?.settings?.department;

  return (
    <Base user={user} department={department}>
      <Container flex="xxl" id="error-page">
        <h1>Ups!</h1>
        <p>Nastala chyba pri načítavaní stránky.</p>
        <p>
          <i>{error.statusText || error.message}</i>
        </p>
      </Container>
    </Base>
  );
}
