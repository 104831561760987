import { Helmet } from "react-helmet";
import { Outlet, useParams, useNavigation } from "react-router-dom";
import TopBar from "../functions/topbar-progress";

import Header from "./Header";
import Footer from "./Footer";

TopBar.config({
  barColors: {
    "0.0": "rgb(13, 110, 253)",
    "1.0": "rgb(13, 202, 240)",
  },
  barThickness: 3,
  shadowBlur: 0,
});

function Base({ user, department, children }) {
  const { id: teamId } = useParams();
  const navigation = useNavigation();

  return (
    <div className="App d-flex flex-column min-vh-100">
      {navigation.state === "loading" && <TopBar />}
      <Helmet>
        <meta charSet="utf-8" />
        {process.env.NODE_ENV === "production" && (
          <script
            defer
            data-domain="theses.kpi.fei.tuke.sk"
            src="https://plausible.kpi.fei.tuke.sk/js/script.tagged-events.js"
          ></script>
        )}
      </Helmet>

      {/* Header */}
      <Header teamId={teamId} user={user} department={department} />

      {children ?? <Outlet />}

      {/* Footer */}
      <Footer />
    </div>
  );
}

export default Base;
