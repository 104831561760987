import React, { useState } from "react";
import {
  Alert,
  Button,
  Col,
  Container,
  Form,
  Row,
  Spinner,
  Stack,
} from "react-bootstrap";
import { redirect, useLoaderData, useRouteLoaderData } from "react-router-dom";
import { formatType } from "../functions/formatting";
import { getCookie } from "../functions/getCookie";

export default function CreateProject() {
  const { settings } = useRouteLoaderData("root");
  const { teachers, terms } = useLoaderData();
  const [title, setTitle] = useState("");
  const [supervisorId, setSupervisorId] = useState("");
  const [type, setType] = useState("");
  const [format, setFormat] = useState(settings.templates[0].id);
  const [validated, setValidated] = useState(false);
  const [creating, setCreating] = useState(false);
  const [error, setError] = useState(null);

  async function submitForm(e) {
    e.preventDefault();
    setError(null);
    setValidated(true);
    if (title === "" || supervisorId === "" || type === "") return;

    setCreating(true);
    try {
      const response = await fetch(`/api/projects/`, {
        method: "POST",
        body: JSON.stringify({
          title: title,
          supervisor: supervisorId,
          type: type,
          format: format,
        }),
        headers: {
          "Content-Type": "application/json",
          "X-CSRFToken": getCookie("csrftoken"),
        },
      });
      const data = await response.json();
      if (response.ok) {
        setCreating(false);
        redirect(`/projects/${data.id}/getting-started`);
      } else {
        throw new Error(data.details);
      }
    } catch (error) {
      console.error("Error:", error);
      setError(error.message);
      setCreating(false);
    }
  }

  function termLabel(term) {
    const sameTypeTerms = terms.filter((t) => t.type === term.type);
    if (sameTypeTerms.length > 1) {
      return `${formatType(term.type)} ${term.year_finishing - 1}/${term.year_finishing}`;
    }
    return formatType(term.type);
  }

  // if terms is empty, it means that there are no enabled terms
  if (terms.length === 0) {
    return (
      <Container fluid="xxl">
        <Alert variant="warning">
          Vytváranie projektov je momentálne pozastavené.
        </Alert>
      </Container>
    );
  }

  const termOptions = terms.map((term) => ({
    value: term.type,
    label: termLabel(term),
  }));

  return (
    <main className="container-xxl">
      <h1 className="h1 mb-4">Vytvoriť projekt pre prácu</h1>

      <Form noValidate validated={validated} onSubmit={submitForm}>
        <Stack gap={3}>
          <Form.Group as={Row} controlId="title">
            <Form.Label column sm={1}>
              Názov:
            </Form.Label>
            <Col sm={11}>
              <Form.Control
                required
                name="title"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
              />
            </Col>
          </Form.Group>

          <Form.Group as={Row}>
            <Form.Label column sm={1}>
              Školiteľ:
            </Form.Label>
            <Col sm="auto">
              <Form.Select
                required
                defaultValue=""
                onChange={(e) => setSupervisorId(e.currentTarget.value)}>
                <option value="" disabled></option>
                {teachers.map((teacher) => (
                  <option value={teacher.id} key={teacher.id}>
                    {teacher.full_name}
                  </option>
                ))}
              </Form.Select>
            </Col>
          </Form.Group>

          <Form.Group as={Row}>
            <Form.Label column sm={1}>
              Typ:
            </Form.Label>
            <Col sm={11}>
              <RadioButtonGroup
                name="type"
                options={termOptions}
                selectedValue={type}
                onChange={setType}
              />
            </Col>
          </Form.Group>

          {settings.templates.length > 1 && (
            <Form.Group as={Row}>
              <Form.Label column sm={1}>
                Šablóna:
              </Form.Label>
              <Col sm={11}>
                <RadioButtonGroup
                  name="format"
                  options={settings.templates}
                  selectedValue={format}
                  onChange={setFormat}
                />
              </Col>
            </Form.Group>
          )}

          <Form.Group as={Row}>
            <Col sm={{ offset: 1, span: 11 }}>
              <Button size="lg" type="submit" disabled={creating}>
                {creating ? (
                  <>
                    <Spinner size="sm" />
                    <span role="status"> Vytvára sa…</span>
                  </>
                ) : (
                  "Vytvoriť"
                )}
              </Button>
            </Col>
          </Form.Group>
          {error && <Alert variant="danger">{error}</Alert>}
        </Stack>
      </Form>
    </main>
  );
}

function RadioButtonGroup({ name, options, selectedValue, onChange }) {
  return (
    <>
      {options.map((option) => (
        <Form.Check
          required
          inline
          type="radio"
          name={name}
          value={option.value}
          id={`${name}-${option.value}`}
          label={option.label}
          checked={selectedValue === option.value}
          onChange={(e) => onChange(e.currentTarget.value)}
          key={option.value}
          className="col-form-label"
        />
      ))}
    </>
  );
}
