export async function rootLoader() {
  const [settingsResponse, userResponse] = await Promise.all([
    fetch("/api/settings/"),
    fetch("/api/user/")
  ]);
  if (!settingsResponse.ok) throw settingsResponse;
  const settings = await settingsResponse.json();
  const user = userResponse.ok ? await userResponse.json() : null;
  return { settings, user };
}
