import { Fragment, useEffect, useState } from "react";
import { Button, Container, Dropdown } from "react-bootstrap";
import { EnvelopeOpen, ThreeDotsVertical } from "react-bootstrap-icons";
import { LinkContainer } from "react-router-bootstrap";

import {
    useFetcher, useLoaderData,
    useRouteLoaderData
} from "react-router-dom";

import "../assets/css/projects.css";
import { VersionsMarks } from "./components";

export function Projects() {
  const { user } = useRouteLoaderData("root");
  const projects = useLoaderData();

  return (
    <Container fluid="xxl">
      <ProjectLists projects={projects} user={user} archive={false} />
    </Container>
  );
}

export function ProjectsArchive() {
  const { user } = useRouteLoaderData("root");
  const projects = useLoaderData();

  return (
    <Container fluid="xxl">
      <ProjectLists projects={projects} user={user} archive />
    </Container>
  );
}

function ProjectLists({ projects, user, archive }) {
  const ownProjects = projects.filter((p) => p.is_own_project);
  const supervisedProjects = projects.filter((p) => !p.is_own_project);

  if (projects.length === 0) {
    return (
      <>
        <p>Žiadne práce.</p>
        {!archive && !user.is_teacher && (
          <LinkContainer to="/create">
            <Button>Vytvoriť projekt pre záverečnú prácu</Button>
          </LinkContainer>
        )}
      </>
    );
  }

  return (
    <>
      {supervisedProjects.length > 0 && (
        <SupervisedProjects projects={supervisedProjects} archive={archive} />
      )}
      {ownProjects.length > 0 && (
        <OwnProjects projects={ownProjects} archive={archive} />
      )}
    </>
  );
}

function OwnProjects({ projects, archive }) {
  return (
    <section>
      <h1>{archive ? "Archív mojich prác" : "Moje práce"}</h1>
      <OwnProjectsList projects={projects} />
    </section>
  );
}

function OwnProjectsList({ projects, onArchive, onUnarchive }) {
  return (
    <div>
      <div className="table-responsive">
        <table className="table table-borderless table-hover">
          <thead>
            <tr className="border-bottom border-dark">
              <th>Typ</th>
              <th>Rok</th>
              <th>Názov</th>
              <th>Školiteľ</th>
              <th></th>
              <th>Odovzdania</th>
            </tr>
          </thead>
          <tbody>
            {projects.map((project) => (
              <OwnProjectLine project={project} key={project.id} />
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}

function OwnProjectLine({ project }) {
  return (
    <tr>
      <td>{project.type}</td>
      <td>{project.year_finishing}</td>
      <td>
        <a
          href={project.gitlab_url}
          className="plausible-event-name=Own+Project+Click">
          {project.title}
        </a>
        {project.language === "en" && (
          <span className="badge rounded-pill bg-secondary ms-1">EN</span>
        )}
      </td>
      <td>
        {project.supervisor.full_name}
        <a
          title="email"
          href={"mailto:" + project.supervisor.email}
          className="ps-2 text-secondary plausible-event-name=Supervisor+Email+Click">
          <EnvelopeOpen />
        </a>
      </td>
      <td>
        <ArtifactLink project={project} />
      </td>
      <td>
        <VersionsMarks versions={project.versions} />
      </td>
    </tr>
  );
}

function SupervisedProjects({ projects, archive }) {
  const fetcher = useFetcher();
  const [localProjects, setLocalProjects] = useState(projects);

  const filteredProjects = localProjects.filter(
    (p) => p.is_archived === archive,
  );

  async function handleArchiveAction(project, action) {
    const updatedProjects = localProjects.map((p) =>
      p.id === project.id ? { ...p, is_archived: action === "archive" } : p,
    );
    setLocalProjects(updatedProjects);

    fetcher.submit(
      {},
      {
        method: "post",
        action: `/${action}/${project.id}`,
      },
    );
  }

  useEffect(() => {
    if (fetcher.data) {
      if (!fetcher.data.success) {
        setLocalProjects(projects);
      }
    }
  }, [fetcher.data, projects]);

  return (
    <section>
      <h1>{archive ? "Archív vedených prác" : "Vedené práce"}</h1>
      <ProjectsList
        projects={filteredProjects}
        onArchive={(project) => handleArchiveAction(project, "archive")}
        onUnarchive={(project) => handleArchiveAction(project, "unarchive")}
      />
    </section>
  );
}

function ProjectsList({ projects, onArchive, onUnarchive }) {
  const projectsByType = Object.groupBy(
    projects,
    (p) => p.year_finishing + "-" + p.type,
  );
  const projectGroups = Object.entries(projectsByType);

  return (
    <div>
      <div className="table-responsive">
        <table className="table table-borderless table-hover">
          <tbody>
            {projectGroups.map(([key, projects]) => (
              <Fragment key={key}>
                <YearTitleLine
                  year={projects[0].year_finishing}
                  type={projects[0].type}
                />
                {projects.map((project) => (
                  <ProjectLine
                    key={project.id}
                    project={project}
                    onArchive={() => onArchive(project)}
                    onUnarchive={() => onUnarchive(project)}
                  />
                ))}
              </Fragment>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}

function ProjectLine({ project, onArchive, onUnarchive }) {
  return (
    <tr>
      <td>{project.student.full_name}</td>
      <td className="ps-0">
        <a
          title="email"
          href={"mailto:" + project.student.email}
          className="text-secondary plausible-event-name=Student+Email+Click">
          <EnvelopeOpen />
        </a>
      </td>
      <td>
        <a
          href={project.gitlab_url}
          className="plausible-event-name=Project+Click">
          {project.title}
        </a>
        {project.language === "en" && (
          <span className="badge rounded-pill bg-secondary ms-1">EN</span>
        )}
      </td>
      <td>
        <ArtifactLink project={project} />
      </td>
      <td>
        <VersionsMarks versions={project.versions} />
      </td>
      <td>
        <MoreDropdown
          project={project}
          onArchive={onArchive}
          onUnarchive={onUnarchive}
        />
      </td>
    </tr>
  );
}

function MoreDropdown({ project, onArchive, onUnarchive }) {
  return (
    <Dropdown>
      <Dropdown.Toggle
        className="nocaret"
        variant="outline-secodnary"
        id={`more-toggle-${project.id}`}>
        <ThreeDotsVertical />
      </Dropdown.Toggle>
      <Dropdown.Menu>
        {/* TODO: Connect with Workweek <DropdownItem>Týždenné výkazy</DropdownItem> */}
        {project.is_archived ? (
          <Dropdown.Item onClick={onUnarchive}>Obnoviť z archívu</Dropdown.Item>
        ) : (
          <Dropdown.Item onClick={onArchive}>Archivovať prácu</Dropdown.Item>
        )}
      </Dropdown.Menu>
    </Dropdown>
  );
}

function YearTitleLine({ type, year }) {
  return (
    <tr className="border-bottom border-dark">
      <th colSpan="4" className="pt-3" id="teams">
        {type} / {year}
      </th>
      <th className="pt-3">Odovzdania</th>
      <th></th>
    </tr>
  );
}

function ArtifactLink({ project }) {
  const aritfactFormat = project.format === "word" ? "DOCX" : "PDF";
  return (
    <a
      href={project.artifact_url}
      className="btn btn-light btn-sm plausible-event-name=PDF+Click">
      {aritfactFormat}
    </a>
  );
}
